import { ColumnFilter } from '@tanstack/react-table'
import { Portal } from '@headlessui/react'
import { XIcon } from '@heroicons/react/outline'
import { useLockedBody } from 'usehooks-ts'
import { usePriceOfferTableContext } from '../table/PriceOfferTable.context'
import { useRouter } from 'next/router'
import { useTranslation } from 'next-i18next'
import Button from '../../../base/button/Button'
import Card from '../../../base/card/Card'
import CardActions from '../../../base/card/CardActions'
import CardText from '../../../base/card/CardText'
import CardTitle from '../../../base/card/CardTitle'
import DemandTable from '../../demand/table/DemandTable'
import NextLink from 'next/link'
import React, { useEffect, useMemo, useState } from 'react'
import usePriceOfferAddOrders from '../../../../hooks/api/price-offer/usePriceOfferAddOrders'
import usePriceOfferCreate from '../../../../hooks/api/price-offer/usePriceOfferCreate'
import useToast from '../../../../hooks/useToast'

export type SelectDemandDialogProps = {
    withPermanentStorage?: boolean
}

const SelectDemandDialog = ({ withPermanentStorage }: SelectDemandDialogProps) => {
    const { t } = useTranslation('common')
    const { push } = useRouter()
    const toast = useToast()
    const [selectedItems, setSelectedItems] = useState<number[]>([])
    const { demandSelector, setDemandSelector, setHighlightedRow } = usePriceOfferTableContext()
    const { mutate: createPriceOffer, isLoading: isLoadingCreatePriceOffer } = usePriceOfferCreate()
    const { mutate: addOrdersToPriceOffer, isLoading: isLoadingAddOrdersToPriceOffer } = usePriceOfferAddOrders(
        withPermanentStorage,
        () => {
            setHighlightedRow(demandSelector.showForId)
            setDemandSelector(undefined)
            toast.success(t('frontend.price_offers.create.demands_select.add.success_toast'))
        }
    )
    const show = demandSelector?.show || false
    useLockedBody(show)

    const initialFilters = useMemo<ColumnFilter[]>(() => {
        return [
            {
                id: 'status',
                value: {
                    type: 'string',
                    value: 'F'
                }
            },
            {
                id: 'without_price_offer',
                value: {
                    type: 'boolean',
                    value: true
                }
            },
            {
                id: 'for_price_offer',
                value: {
                    type: 'string',
                    value: demandSelector?.showForId || '0'
                }
            }
        ]
    }, [demandSelector?.showForId])

    const disabledFilters = useMemo<string[]>(() => {
        return ['status']
    }, [])

    useEffect(() => {
        if (demandSelector?.show) {
            setSelectedItems([])
        }
    }, [demandSelector])

    const onClickAddOrders = () => {
        if (demandSelector.showForId) {
            addOrdersToPriceOffer({
                id: demandSelector.showForId,
                orders: selectedItems
            })
            return
        }
        createPriceOffer(
            {
                orders: selectedItems
            },
            {
                onSuccess: data => {
                    setDemandSelector(undefined)
                    push(`/app/price-offers/create/${data.id}`)
                }
            }
        )
    }

    const renderTitle = () => {
        return typeof demandSelector?.showForId !== 'undefined'
            ? t('frontend.price_offers.create.demands_select_to_exists_price_offer.title')
            : t('frontend.price_offers.create.demands_select.title')
    }

    const renderActionTitle = () => {
        return typeof demandSelector?.showForId !== 'undefined'
            ? t('frontend.price_offers.create.demands_select.add')
            : t('frontend.price_offers.create.demands_select.create')
    }

    return (
        show && (
            <Portal>
                <div className='fixed h-full w-full left-0 right-0 top-0 bottom-0 z-[999] pointer-events-none'>
                    <div className='fixed h-full w-full bg-black bg-opacity-50 z-[998] left-0 right-0 top-0 bottom-0 pointer-events-auto' />
                    <div className='fixed w-full p-20 z-[999] left-0 top-0 pointer-events-auto overflow-hidden'>
                        <Card airy className='mx-auto h-full max-w-7xl' hideOverflow>
                            <CardTitle border={false} basicTitle>
                                <div className='flex items-center justify-between gap-2.5 w-full'>
                                    <h6 className='card-headline'>
                                        {renderTitle()}
                                        <NextLink
                                            href={{
                                                pathname: '/app/demand/create',
                                                query: { priceOffer: demandSelector.showForId }
                                            }}
                                            className='text-primary-300 underline'
                                        >
                                            {t('frontend.price_offers.create.create_new_demand.title')}
                                        </NextLink>
                                    </h6>
                                    <Button
                                        leftIcon={<XIcon />}
                                        onClick={() => {
                                            return setDemandSelector(undefined)
                                        }}
                                        disabled={isLoadingCreatePriceOffer || isLoadingAddOrdersToPriceOffer}
                                    >
                                        {t('frontend.price_offers.create.demands_select.cancel')}
                                    </Button>
                                </div>
                            </CardTitle>
                            <CardText>
                                <div className='overflow-y-auto relative'>
                                    <DemandTable
                                        sticky
                                        stickyPagination
                                        hideActions
                                        selecting
                                        height={400}
                                        minHeight={320}
                                        initialFilters={initialFilters}
                                        disabledFilters={disabledFilters}
                                        onSelectItems={setSelectedItems}
                                        customEmpty={
                                            demandSelector && (
                                                <>
                                                    Žádné poptávky nejsou vytvořeny,&nbsp;
                                                    <NextLink
                                                        href={{
                                                            pathname: '/app/demand/create',
                                                            query: { priceOffer: demandSelector.showForId }
                                                        }}
                                                        className='text-primary-300 underline'
                                                    >
                                                        vytvořte první zde
                                                    </NextLink>
                                                </>
                                            )
                                        }
                                        disableActionColumn
                                        selectOnClickRow
                                    />
                                </div>
                            </CardText>
                            <CardActions className='justify-end'>
                                <Button
                                    disabled={Object.entries(selectedItems).length === 0}
                                    loading={isLoadingCreatePriceOffer || isLoadingAddOrdersToPriceOffer}
                                    onClick={onClickAddOrders}
                                >
                                    {renderActionTitle()}
                                </Button>
                            </CardActions>
                        </Card>
                    </div>
                </div>
            </Portal>
        )
    )
}

export default SelectDemandDialog
