import { buildClassesWithDefault } from '../../../utils/StyleHelper'
import React, { HTMLAttributes, PropsWithChildren } from 'react'

export type CardTitleProps = HTMLAttributes<HTMLDivElement> & {
    disabled?: boolean
    border?: boolean
    subCard?: boolean
    basicTitle?: boolean
    lighterBg?: boolean
    transparentBg?: boolean
    tableCardTitle?: boolean
}

const CardTitle = ({
    children,
    border = false,
    className,
    disabled = false,
    subCard = false,
    basicTitle = false,
    lighterBg = false,
    transparentBg = false,
    tableCardTitle = false,
    ...props
}: PropsWithChildren<CardTitleProps>) => {
    const effectiveTransparentBg = basicTitle || transparentBg

    return (
        <div
            {...props}
            className={buildClassesWithDefault(
                {
                    'card-title': true,
                    'card-title-border': border,
                    disabled,
                    'main-card': !subCard && !basicTitle,
                    'sub-card': subCard && !basicTitle,
                    'main-card-normal-bg': !lighterBg && !effectiveTransparentBg,
                    'main-card-lighter-bg': lighterBg,
                    'card-title-normal-h': !lighterBg,
                    'card-title-smaller-h': lighterBg,
                    '!py-3 !px-5': tableCardTitle
                },
                className
            )}
        >
            {children}
            {disabled && <div className='disabled-overlay' />}
        </div>
    )
}

CardTitle.displayName = 'CardTitle'

export default CardTitle
